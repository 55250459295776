<template>
    <div class="d-flex flex-column">
        <div
            v-if="from > 1"
            class="d-flex flex-column load-more load-more--previous"
        >
            <div class="load-more__preview">
                <slot name="previous"></slot>
            </div>
            <div
                class="d-flex flex-column load-more__button align-items-center"
            >
                <div class="load-more__info">
                    <span class="equal-small-2">
                        {{ displayTo }}
                        {{ t('loadMore.of') }} {{ total }}
                        {{ name }}
                    </span>
                </div>
                <FormButton
                    :label="t('loadMore.previous', { count: size })"
                    class="l-button--secondary d-block"
                    :href="prevUrl"
                    component="a"
                    @click="previous"
                />
            </div>
        </div>
        <slot name="default"></slot>
        <div v-if="to < pages" class="load-more">
            <div class="load-more__preview">
                <slot name="next"></slot>
            </div>
            <div
                class="d-flex flex-column load-more__button align-items-center mt-5"
            >
                <FormButton
                    :label="t('loadMore.next', { count: displaySizeNext })"
                    component="a"
                    :href="nextUrl"
                    class="l-button--secondary"
                    @click="next"
                    :disabled="loading"
                />
                <div class="load-more__info">
                    <span class="equal-small-2">
                        {{ displayTo }}
                        {{ t('loadMore.of') }} {{ total }}
                        {{ name }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    name: {
        type: String,
    },
    page: {
        type: Number,
        required: true,
    },
    size: {
        type: Number,
        required: true,
    },
    pages: {
        type: Number,
        required: true,
    },
    total: {
        type: Number,
        required: true,
    },
    initial: {
        type: Object,
        default: () => ({
            from: 1,
            to: 1,
        }),
    },
    loading: {
        type: Boolean,
        default: false,
    },
    nextUrl: {
        type: String,
        default: '',
    },
    prevUrl: {
        type: String,
        default: '',
    },
});

// const from = toRef(props.initial, 'from');
// const to = toRef(props.initial, 'to');

const from = ref(props.initial.from);
const to = ref(props.initial.to);

const displayFrom = computed(() => {
    return (from.value - 1) * props.size + 1;
});

const displayTo = computed(() => {
    return to.value * props.size > props.total
        ? props.total
        : to.value * props.size;
});

const displaySizeNext = computed(() => {
    return Math.min(props.size, props.total - displayTo.value);
});

function previous(e) {
    e.preventDefault();
    from.value = Math.max(0, from.value - 1);
    emit('previous', from.value);
}

function next(e) {
    e.preventDefault();
    to.value = Math.min(props.pages, to.value + 1);
    emit('next', to.value);
}

const emit = defineEmits(['previous', 'next']);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.hidden {
    display: none;
}
.load-more {
    overflow: hidden;
    position: relative;
    height: 130px;

    &::after {
        z-index: 10;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 1) 40%
        );
    }

    &__preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    &__button {
        z-index: 11;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        button {
            margin: auto auto 0 auto;
        }
    }

    &__info {
        display: flex;
        height: 50px;
        text-align: center;

        span {
            margin: auto;
            color: $color-disabled;
        }
    }

    &--previous {
        &::after {
            background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 1) 40%
            );
        }

        .load-more {
            &__preview {
                top: unset;
                bottom: 0;
            }

            &__button {
                button {
                    margin: 0 auto auto auto;
                }
            }
        }
    }
}
</style>
